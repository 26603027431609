<template>
  <header data-v-99368da2="" class="header">
    <div data-v-99368da2="" class="container">
      <div data-v-99368da2="" class="header__body">
        <RouterLink data-v-99368da2="" to="/" aria-current="page" class="logo">
          <img data-v-99368da2="" src="/img/logo.png" alt="">
        </RouterLink>
        <nav data-v-99368da2="" class="hidden-md">
          <RouterLink to="/" data-v-99368da2="" :class="[$route.name === 'Home' ? 'active' : '']">
            <span data-v-99368da2="">Кейсы</span>
          </RouterLink>
          <RouterLink to="/bust" data-v-99368da2="" :class="[$route.name === 'Bust' ? 'active' : '']">
            <span data-v-99368da2="">Буст</span>
          </RouterLink>
          <RouterLink to="/upgrade" data-v-99368da2="" :class="[$route.name === 'Upgrade' ? 'active' : '']">
            <span data-v-99368da2="">Апгрейд</span>
          </RouterLink>
          <RouterLink data-v-99368da2="" to="/partner" class="green active">
            <span data-v-99368da2="">Партнерка</span>
          </RouterLink>
        </nav>
        <div data-v-99368da2="" class="socials hidden-md"><a data-v-99368da2="" href="https://vk.com/gmdrop_official"
                                                             target="_blank" class="social__item">
          <svg data-v-99368da2="" width="18" height="19" viewBox="0 0 18 19" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g data-v-99368da2="" clip-path="url(#clip0)">
              <path data-v-99368da2="" fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.6063 5.16646C17.7255 4.7746 17.6063 4.48608 17.0358 4.48608H15.1518C14.6724 4.48608 14.4513 4.73512 14.3315 5.01C14.3315 5.01 13.3734 7.30376 12.0162 8.7937C11.577 9.22575 11.3775 9.36283 11.1377 9.36283C11.0179 9.36283 10.8377 9.22575 10.8377 8.83317V5.16646C10.8377 4.69565 10.7057 4.48608 10.3067 4.48608H7.344C7.04473 4.48608 6.86458 4.70426 6.86458 4.91168C6.86458 5.35737 7.54352 5.46072 7.61314 6.71525V9.44106C7.61314 10.0389 7.50333 10.1473 7.26362 10.1473C6.62487 10.1473 5.07106 7.84275 4.14882 5.20593C3.97011 4.69278 3.78926 4.48608 3.30768 4.48608H1.42229C0.884022 4.48608 0.776367 4.73512 0.776367 5.01C0.776367 5.49947 1.41512 7.93103 3.75122 11.147C5.30862 13.3432 7.50118 14.5338 9.49853 14.5338C10.6964 14.5338 10.8442 14.2697 10.8442 13.814V12.1539C10.8442 11.625 10.9576 11.5195 11.3373 11.5195C11.6172 11.5195 12.0959 11.6573 13.214 12.7159C14.4915 13.9712 14.7018 14.5338 15.421 14.5338H17.3049C17.8432 14.5338 18.113 14.2697 17.958 13.7472C17.7872 13.2276 17.1772 12.4733 16.3683 11.5784C15.9291 11.0688 15.2702 10.5198 15.07 10.2449C14.7908 9.89249 14.8705 9.73531 15.07 9.42168C15.07 9.42168 17.3666 6.24516 17.6056 5.16646H17.6063Z"
                    fill="#5A5A61"></path>
            </g>
            <defs data-v-99368da2="">
              <clipPath data-v-99368da2="" id="clip0">
                <rect data-v-99368da2="" width="17.2247" height="17.2247" fill="white"
                      transform="translate(0.776367 0.897705)"></rect>
              </clipPath>
            </defs>
          </svg>
        </a><a data-v-99368da2="" href="https://www.instagram.com/gmdrop.gg/" target="_blank" class="social__item">
          <svg data-v-99368da2="" width="17" height="18" viewBox="0 0 17 18" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g data-v-99368da2="" clip-path="url(#clip0)">
              <path data-v-99368da2=""
                    d="M8.27748 6.57475C6.82809 6.57475 5.64528 7.75756 5.64528 9.20694C5.64528 10.6563 6.82809 11.8391 8.27748 11.8391C9.72686 11.8391 10.9097 10.6563 10.9097 9.20694C10.9097 7.75756 9.72686 6.57475 8.27748 6.57475ZM16.1721 9.20694C16.1721 8.11694 16.182 7.03681 16.1207 5.94879C16.0595 4.68502 15.7712 3.56342 14.8471 2.63929C13.921 1.71319 12.8014 1.42686 11.5376 1.36565C10.4476 1.30443 9.36748 1.31431 8.27945 1.31431C7.18945 1.31431 6.10932 1.30443 5.0213 1.36565C3.75753 1.42686 2.63593 1.71516 1.7118 2.63929C0.785695 3.5654 0.499372 4.68502 0.438158 5.94879C0.376945 7.03879 0.386818 8.11892 0.386818 9.20694C0.386818 10.295 0.376945 11.3771 0.438158 12.4651C0.499372 13.7289 0.78767 14.8505 1.7118 15.7746C2.63791 16.7007 3.75753 16.987 5.0213 17.0482C6.1113 17.1094 7.19143 17.0996 8.27945 17.0996C9.36945 17.0996 10.4496 17.1094 11.5376 17.0482C12.8014 16.987 13.923 16.6987 14.8471 15.7746C15.7732 14.8485 16.0595 13.7289 16.1207 12.4651C16.1839 11.3771 16.1721 10.2969 16.1721 9.20694ZM8.27748 13.2569C6.03626 13.2569 4.22749 11.4482 4.22749 9.20694C4.22749 6.96573 6.03626 5.15696 8.27748 5.15696C10.5187 5.15696 12.3275 6.96573 12.3275 9.20694C12.3275 11.4482 10.5187 13.2569 8.27748 13.2569ZM12.4933 5.93694C11.9701 5.93694 11.5475 5.51437 11.5475 4.99109C11.5475 4.46781 11.9701 4.04523 12.4933 4.04523C13.0166 4.04523 13.4392 4.46781 13.4392 4.99109C13.4393 5.11534 13.415 5.23841 13.3675 5.35323C13.32 5.46806 13.2504 5.57239 13.1625 5.66025C13.0746 5.74811 12.9703 5.81778 12.8555 5.86526C12.7407 5.91274 12.6176 5.93709 12.4933 5.93694Z"
                    fill="#5A5A61"></path>
            </g>
            <defs data-v-99368da2="">
              <clipPath data-v-99368da2="" id="clip0">
                <rect data-v-99368da2="" width="16.3371" height="16.3371" fill="white"
                      transform="translate(0.38623 0.897705)"></rect>
              </clipPath>
            </defs>
          </svg>
        </a><!----><a data-v-99368da2="" href="https://t.me/gmdropcases" target="_blank" class="social__item">
          <svg data-v-99368da2="" width="18" height="14" viewBox="0 0 18 14" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path data-v-99368da2=""
                  d="M16.5387 0.159118L0.591584 5.87106C-0.0501583 6.13706 -0.267213 6.66974 0.436474 6.95883L4.52761 8.16645L14.4194 2.48816C14.9595 2.13169 15.5125 2.22674 15.0367 2.61889L6.54093 9.76377L6.27406 12.7875C6.52124 13.2543 6.97385 13.2565 7.26255 13.0245L9.61302 10.9587L13.6386 13.7586C14.5736 14.2727 15.0823 13.9409 15.2835 12.9986L17.9239 1.3857C18.198 0.22578 17.7305 -0.285295 16.5387 0.159118Z"
                  fill="#5A5A61"></path>
          </svg>
        </a><a data-v-99368da2="" href="https://www.facebook.com/Gmdrop-Cases-Standoff-2-110098151255183"
               target="_blank" class="social__item">
          <svg data-v-99368da2="" height="14" viewBox="0 0 24 24" width="17" xmlns="http://www.w3.org/2000/svg">
            <path data-v-99368da2=""
                  d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                  fill="#5A5A61"></path>
          </svg>
        </a></div>
        <div data-v-99368da2="" class="sound hidden-md" @click="$root.setSound()">
          <svg v-if="$root.sound" data-v-99368da2="" width="31" height="31" viewBox="0 0 31 31" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path data-v-99368da2=""
                  d="M17.4375 7.75009C17.4372 7.5678 17.3856 7.38928 17.2884 7.23505C17.1912 7.08082 17.0525 6.95714 16.8882 6.87822C16.7239 6.79929 16.5406 6.76834 16.3595 6.7889C16.1783 6.80946 16.0067 6.88071 15.8643 6.99446L11.2859 10.6563H6.78125C6.52432 10.6563 6.27792 10.7584 6.09624 10.9401C5.91456 11.1218 5.8125 11.3682 5.8125 11.6251V19.3751C5.8125 19.632 5.91456 19.8784 6.09624 20.0601C6.27792 20.2418 6.52432 20.3438 6.78125 20.3438H11.2859L15.8643 24.0057C16.0067 24.1195 16.1783 24.1907 16.3595 24.2113C16.5406 24.2318 16.7239 24.2009 16.8882 24.122C17.0525 24.043 17.1912 23.9194 17.2884 23.7651C17.3856 23.6109 17.4372 23.4324 17.4375 23.2501V7.75009ZM12.2295 12.3807L15.5 9.76509V21.2351L12.2295 18.6195C12.058 18.4819 11.8448 18.4067 11.625 18.4063H7.75V12.5938H11.625C11.8448 12.5934 12.058 12.5183 12.2295 12.3807ZM23.2984 15.5001C23.2999 16.6453 23.0751 17.7795 22.6369 18.8375C22.1986 19.8955 21.5556 20.8565 20.7448 21.6652L19.375 20.2954C20.0056 19.6664 20.5057 18.9189 20.8465 18.0959C21.1874 17.273 21.3622 16.3908 21.3609 15.5001C21.3621 14.6094 21.1872 13.7272 20.8464 12.9043C20.5056 12.0814 20.0055 11.3339 19.375 10.7048L20.7448 9.33496C21.5556 10.1437 22.1986 11.1047 22.6369 12.1627C23.0751 13.2207 23.2999 14.3549 23.2984 15.5001Z"
                  fill="white"></path>
          </svg>
          <svg v-else data-v-99368da2="" width="30" height="30" viewBox="0 0 25 25" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path data-v-99368da2=""
                  d="M14.0625 6.25011C14.0623 6.1031 14.0206 5.95914 13.9422 5.83476C13.8639 5.71038 13.752 5.61063 13.6195 5.54699C13.487 5.48334 13.3392 5.45837 13.1931 5.47496C13.0471 5.49154 12.9086 5.549 12.7938 5.64073L9.10156 8.59386H5.46875C5.26155 8.59386 5.06284 8.67617 4.91632 8.82268C4.76981 8.9692 4.6875 9.16791 4.6875 9.37511V15.6251C4.6875 15.8323 4.76981 16.031 4.91632 16.1775C5.06284 16.3241 5.26155 16.4064 5.46875 16.4064H9.10156L12.7938 19.3595C12.9086 19.4512 13.0471 19.5087 13.1931 19.5253C13.3392 19.5418 13.487 19.5169 13.6195 19.4532C13.752 19.3896 13.8639 19.2898 13.9422 19.1655C14.0206 19.0411 14.0623 18.8971 14.0625 18.7501V6.25011ZM9.8625 9.98448L12.5 7.87511V17.1251L9.8625 15.0157C9.72421 14.9048 9.5523 14.8442 9.375 14.8439H6.25V10.1564H9.375C9.5523 10.156 9.72421 10.0954 9.8625 9.98448ZM18.7891 12.5001C18.7903 13.4236 18.609 14.3383 18.2555 15.1916C17.9021 16.0448 17.3836 16.8198 16.7297 17.472L15.625 16.3673C16.1336 15.86 16.5369 15.2572 16.8117 14.5935C17.0866 13.9299 17.2276 13.2184 17.2266 12.5001C17.2275 11.7818 17.0864 11.0704 16.8116 10.4067C16.5368 9.74308 16.1335 9.14027 15.625 8.63292L16.7297 7.52823C17.3836 8.18043 17.9021 8.95542 18.2555 9.80866C18.609 10.6619 18.7903 11.5766 18.7891 12.5001Z"
                  fill="#FFBF11"></path>
            <line data-v-99368da2="" x1="22.3536" y1="3.35355" x2="4.35355" y2="21.3536" stroke="#FF3133"></line>
          </svg>
        </div>
        <div v-if="!$root.user" data-v-5d470ef2="" data-v-99368da2="" class="user-info">
          <div data-v-5d470ef2="" class="hidden-md auth-socials">
            <a data-v-5d470ef2="" @click="$root.openAuthWindow('/api/auth/steam')" class="auth-item google">
              <img data-v-5d470ef2="" src="/img/steam-auth.svg" alt="">
            </a>
          </div>
        </div>
        <div v-else data-v-5d470ef2="" data-v-99368da2="" class="user-info">
          <div data-v-5d470ef2="" class="user">
            <RouterLink data-v-5d470ef2="" to="/profile" class="user__body">
              <div data-v-5d470ef2="" class="avatar">
                <img data-v-5d470ef2=""
                     :src="$root.user.avatar"
                     alt="">
              </div>
              <div data-v-5d470ef2="" class="info">
                <div data-v-5d470ef2="" class="nickname">{{ $root.user.username }}</div>
                <div data-v-5d470ef2="" class="balance">{{ $root.user.balance | num }} ₽</div>
              </div>
            </RouterLink>
            <button @click="$root.$emit('showDepositModal')" data-v-5d470ef2="" class="btn deposit">Пополнить</button>
            <button @click="$root.$emit('showDepositModal')" data-v-5d470ef2="" class="btn deposit mobile">+</button>
            <a data-v-5d470ef2="" @click="logOut()" class="exit">
              <img data-v-5d470ef2="" src="/img/exit.svg" alt="">
            </a>
          </div>
        </div>
        <div @click="mobileTab = !mobileTab" data-v-99368da2="" class="burger">
          <img data-v-99368da2="" src="/img/burger.svg" alt="">
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="mobileTab" data-v-48a0ebc1="" data-v-99368da2="" class="wrapper-mobile-menu">
        <div data-v-48a0ebc1="" class="mobile-menu">
          <div v-if="$root.user" data-v-7c1f1fc4="" data-v-48a0ebc1="" class="user-info">
            <div data-v-7c1f1fc4="" class="user">
              <a data-v-7c1f1fc4="" @click="goToUrl('/profile')" class="user__body">
                <div data-v-7c1f1fc4="" class="avatar"><img data-v-7c1f1fc4="" :src="$root.user.avatar"
                                                            alt=""></div>
                <div data-v-7c1f1fc4="" class="info">
                  <div data-v-7c1f1fc4="" class="nickname">{{ $root.user.username }}</div>
                  <div data-v-7c1f1fc4="" class="balance">{{ $root.user.balance | num }} ₽</div>
                </div>
              </a>
              <button @click="$root.$emit('showDepositModal')" data-v-7c1f1fc4="" class="btn deposit">+</button>
              <a data-v-7c1f1fc4="" @click="logOut" class="exit"><img data-v-7c1f1fc4="" src="/img/exit.svg"
                                                                      alt=""></a>
            </div>
          </div>
          <div data-v-7c1f1fc4="" v-else data-v-48a0ebc1="" class="user-info">
            <div data-v-7c1f1fc4="" class=" auth-socials">
              <a data-v-7c1f1fc4="" @click="$root.openAuthWindow('/api/auth/steam')" class="auth-item google">
                <img data-v-7c1f1fc4="" src="/img/steam-auth.svg" alt="">
              </a>
            </div>
          </div>
          <div data-v-48a0ebc1="" class="nav">
            <a @click="goToUrl('/')" data-v-48a0ebc1="" :class="[$route.name === 'Home' ? 'active' : '']">
              <span data-v-48a0ebc1="">Кейсы</span>
            </a>
            <a @click="goToUrl('/bust')" data-v-48a0ebc1="" :class="[$route.name === 'Bust' ? 'active' : '']">
              <span data-v-48a0ebc1="">Буст</span>
            </a>
            <a @click="goToUrl('/upgrade')" data-v-48a0ebc1="" :class="[$route.name === 'Upgrade' ? 'active' : '']">
              <span data-v-48a0ebc1="">Апгрейд</span>
            </a>
            <a @click="goToUrl('/partner')" data-v-48a0ebc1="" class="green">
              <span data-v-48a0ebc1="">Партнерка</span>
            </a>
          </div>
          <div @click="$root.setSound()" v-if="$root.sound" data-v-48a0ebc1="" class="sound">
            <div data-v-48a0ebc1="" class="img">
              <svg data-v-48a0ebc1="" width="20" height="20" viewBox="0 0 31 31" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path data-v-48a0ebc1=""
                      d="M17.4375 7.75009C17.4372 7.5678 17.3856 7.38928 17.2884 7.23505C17.1912 7.08082 17.0525 6.95714 16.8882 6.87822C16.7239 6.79929 16.5406 6.76834 16.3595 6.7889C16.1783 6.80946 16.0067 6.88071 15.8643 6.99446L11.2859 10.6563H6.78125C6.52432 10.6563 6.27792 10.7584 6.09624 10.9401C5.91456 11.1218 5.8125 11.3682 5.8125 11.6251V19.3751C5.8125 19.632 5.91456 19.8784 6.09624 20.0601C6.27792 20.2418 6.52432 20.3438 6.78125 20.3438H11.2859L15.8643 24.0057C16.0067 24.1195 16.1783 24.1907 16.3595 24.2113C16.5406 24.2318 16.7239 24.2009 16.8882 24.122C17.0525 24.043 17.1912 23.9194 17.2884 23.7651C17.3856 23.6109 17.4372 23.4324 17.4375 23.2501V7.75009ZM12.2295 12.3807L15.5 9.76509V21.2351L12.2295 18.6195C12.058 18.4819 11.8448 18.4067 11.625 18.4063H7.75V12.5938H11.625C11.8448 12.5934 12.058 12.5183 12.2295 12.3807ZM23.2984 15.5001C23.2999 16.6453 23.0751 17.7795 22.6369 18.8375C22.1986 19.8955 21.5556 20.8565 20.7448 21.6652L19.375 20.2954C20.0056 19.6664 20.5057 18.9189 20.8465 18.0959C21.1874 17.273 21.3622 16.3908 21.3609 15.5001C21.3621 14.6094 21.1872 13.7272 20.8464 12.9043C20.5056 12.0814 20.0055 11.3339 19.375 10.7048L20.7448 9.33496C21.5556 10.1437 22.1986 11.1047 22.6369 12.1627C23.0751 13.2207 23.2999 14.3549 23.2984 15.5001Z"
                      fill="white"></path>
              </svg>
            </div>
            <span data-v-48a0ebc1="">
              Звук:
            </span>
            <em data-v-48a0ebc1="" style="color: rgb(255, 255, 255);">Вкл.</em>
          </div>
          <div @click="$root.setSound()" v-else data-v-48a0ebc1="" class="sound">
            <div data-v-48a0ebc1="" class="img">
              <svg data-v-48a0ebc1="" width="20" height="20" viewBox="0 0 25 25" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path data-v-48a0ebc1=""
                      d="M14.0625 6.25011C14.0623 6.1031 14.0206 5.95914 13.9422 5.83476C13.8639 5.71038 13.752 5.61063 13.6195 5.54699C13.487 5.48334 13.3392 5.45837 13.1931 5.47496C13.0471 5.49154 12.9086 5.549 12.7938 5.64073L9.10156 8.59386H5.46875C5.26155 8.59386 5.06284 8.67617 4.91632 8.82268C4.76981 8.9692 4.6875 9.16791 4.6875 9.37511V15.6251C4.6875 15.8323 4.76981 16.031 4.91632 16.1775C5.06284 16.3241 5.26155 16.4064 5.46875 16.4064H9.10156L12.7938 19.3595C12.9086 19.4512 13.0471 19.5087 13.1931 19.5253C13.3392 19.5418 13.487 19.5169 13.6195 19.4532C13.752 19.3896 13.8639 19.2898 13.9422 19.1655C14.0206 19.0411 14.0623 18.8971 14.0625 18.7501V6.25011ZM9.8625 9.98448L12.5 7.87511V17.1251L9.8625 15.0157C9.72421 14.9048 9.5523 14.8442 9.375 14.8439H6.25V10.1564H9.375C9.5523 10.156 9.72421 10.0954 9.8625 9.98448ZM18.7891 12.5001C18.7903 13.4236 18.609 14.3383 18.2555 15.1916C17.9021 16.0448 17.3836 16.8198 16.7297 17.472L15.625 16.3673C16.1336 15.86 16.5369 15.2572 16.8117 14.5935C17.0866 13.9299 17.2276 13.2184 17.2266 12.5001C17.2275 11.7818 17.0864 11.0704 16.8116 10.4067C16.5368 9.74308 16.1335 9.14027 15.625 8.63292L16.7297 7.52823C17.3836 8.18043 17.9021 8.95542 18.2555 9.80866C18.609 10.6619 18.7903 11.5766 18.7891 12.5001Z"
                      fill="#FFBF11"></path>
                <line data-v-48a0ebc1="" x1="22.3536" y1="3.35355" x2="4.35355" y2="21.3536" stroke="#FF3133"></line>
              </svg>
            </div>
            <span data-v-48a0ebc1="">Звук:</span><em data-v-48a0ebc1="">Выкл.</em>
          </div>
          <div data-v-48a0ebc1="" class="socials"><a data-v-48a0ebc1="" href="https://vk.com/gmdrop_official"
                                                     target="_blank" class="social__item">
            <svg data-v-48a0ebc1="" width="18" height="19" viewBox="0 0 18 19" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <g data-v-48a0ebc1="" clip-path="url(#clip0)">
                <path data-v-48a0ebc1="" fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.6063 5.16646C17.7255 4.7746 17.6063 4.48608 17.0358 4.48608H15.1518C14.6724 4.48608 14.4513 4.73512 14.3315 5.01C14.3315 5.01 13.3734 7.30376 12.0162 8.7937C11.577 9.22575 11.3775 9.36283 11.1377 9.36283C11.0179 9.36283 10.8377 9.22575 10.8377 8.83317V5.16646C10.8377 4.69565 10.7057 4.48608 10.3067 4.48608H7.344C7.04473 4.48608 6.86458 4.70426 6.86458 4.91168C6.86458 5.35737 7.54352 5.46072 7.61314 6.71525V9.44106C7.61314 10.0389 7.50333 10.1473 7.26362 10.1473C6.62487 10.1473 5.07106 7.84275 4.14882 5.20593C3.97011 4.69278 3.78926 4.48608 3.30768 4.48608H1.42229C0.884022 4.48608 0.776367 4.73512 0.776367 5.01C0.776367 5.49947 1.41512 7.93103 3.75122 11.147C5.30862 13.3432 7.50118 14.5338 9.49853 14.5338C10.6964 14.5338 10.8442 14.2697 10.8442 13.814V12.1539C10.8442 11.625 10.9576 11.5195 11.3373 11.5195C11.6172 11.5195 12.0959 11.6573 13.214 12.7159C14.4915 13.9712 14.7018 14.5338 15.421 14.5338H17.3049C17.8432 14.5338 18.113 14.2697 17.958 13.7472C17.7872 13.2276 17.1772 12.4733 16.3683 11.5784C15.9291 11.0688 15.2702 10.5198 15.07 10.2449C14.7908 9.89249 14.8705 9.73531 15.07 9.42168C15.07 9.42168 17.3666 6.24516 17.6056 5.16646H17.6063Z"
                      fill="#5A5A61"></path>
              </g>
              <defs data-v-48a0ebc1="">
                <clipPath data-v-48a0ebc1="" id="clip0">
                  <rect data-v-48a0ebc1="" width="17.2247" height="17.2247" fill="white"
                        transform="translate(0.776367 0.897705)"></rect>
                </clipPath>
              </defs>
            </svg>
          </a><a data-v-48a0ebc1="" href="https://www.instagram.com/gmdrop.gg/" target="_blank" class="social__item">
            <svg data-v-48a0ebc1="" width="17" height="18" viewBox="0 0 17 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <g data-v-48a0ebc1="" clip-path="url(#clip0)">
                <path data-v-48a0ebc1=""
                      d="M8.27748 6.57475C6.82809 6.57475 5.64528 7.75756 5.64528 9.20694C5.64528 10.6563 6.82809 11.8391 8.27748 11.8391C9.72686 11.8391 10.9097 10.6563 10.9097 9.20694C10.9097 7.75756 9.72686 6.57475 8.27748 6.57475ZM16.1721 9.20694C16.1721 8.11694 16.182 7.03681 16.1207 5.94879C16.0595 4.68502 15.7712 3.56342 14.8471 2.63929C13.921 1.71319 12.8014 1.42686 11.5376 1.36565C10.4476 1.30443 9.36748 1.31431 8.27945 1.31431C7.18945 1.31431 6.10932 1.30443 5.0213 1.36565C3.75753 1.42686 2.63593 1.71516 1.7118 2.63929C0.785695 3.5654 0.499372 4.68502 0.438158 5.94879C0.376945 7.03879 0.386818 8.11892 0.386818 9.20694C0.386818 10.295 0.376945 11.3771 0.438158 12.4651C0.499372 13.7289 0.78767 14.8505 1.7118 15.7746C2.63791 16.7007 3.75753 16.987 5.0213 17.0482C6.1113 17.1094 7.19143 17.0996 8.27945 17.0996C9.36945 17.0996 10.4496 17.1094 11.5376 17.0482C12.8014 16.987 13.923 16.6987 14.8471 15.7746C15.7732 14.8485 16.0595 13.7289 16.1207 12.4651C16.1839 11.3771 16.1721 10.2969 16.1721 9.20694ZM8.27748 13.2569C6.03626 13.2569 4.22749 11.4482 4.22749 9.20694C4.22749 6.96573 6.03626 5.15696 8.27748 5.15696C10.5187 5.15696 12.3275 6.96573 12.3275 9.20694C12.3275 11.4482 10.5187 13.2569 8.27748 13.2569ZM12.4933 5.93694C11.9701 5.93694 11.5475 5.51437 11.5475 4.99109C11.5475 4.46781 11.9701 4.04523 12.4933 4.04523C13.0166 4.04523 13.4392 4.46781 13.4392 4.99109C13.4393 5.11534 13.415 5.23841 13.3675 5.35323C13.32 5.46806 13.2504 5.57239 13.1625 5.66025C13.0746 5.74811 12.9703 5.81778 12.8555 5.86526C12.7407 5.91274 12.6176 5.93709 12.4933 5.93694Z"
                      fill="#5A5A61"></path>
              </g>
              <defs data-v-48a0ebc1="">
                <clipPath data-v-48a0ebc1="" id="clip0">
                  <rect data-v-48a0ebc1="" width="16.3371" height="16.3371" fill="white"
                        transform="translate(0.38623 0.897705)"></rect>
                </clipPath>
              </defs>
            </svg>
          </a><!----><a data-v-48a0ebc1="" href="https://t.me/gmdropcases" target="_blank" class="social__item">
            <svg data-v-48a0ebc1="" width="18" height="14" viewBox="0 0 18 14" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path data-v-48a0ebc1=""
                    d="M16.5387 0.159118L0.591584 5.87106C-0.0501583 6.13706 -0.267213 6.66974 0.436474 6.95883L4.52761 8.16645L14.4194 2.48816C14.9595 2.13169 15.5125 2.22674 15.0367 2.61889L6.54093 9.76377L6.27406 12.7875C6.52124 13.2543 6.97385 13.2565 7.26255 13.0245L9.61302 10.9587L13.6386 13.7586C14.5736 14.2727 15.0823 13.9409 15.2835 12.9986L17.9239 1.3857C18.198 0.22578 17.7305 -0.285295 16.5387 0.159118Z"
                    fill="#5A5A61"></path>
            </svg>
          </a><a data-v-48a0ebc1="" href="https://www.facebook.com/Gmdrop-Cases-Standoff-2-110098151255183"
                 target="_blank"
                 class="social__item">
            <svg data-v-48a0ebc1="" height="14" viewBox="0 0 24 24" width="17" xmlns="http://www.w3.org/2000/svg">
              <path data-v-48a0ebc1=""
                    d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                    fill="#5A5A61"></path>
            </svg>
          </a></div>
          <div data-v-48a0ebc1="" class="wrapper">
            <a data-v-48a0ebc1="" @click="goToUrl('/chat')" class="open-chat">
            <div data-v-48a0ebc1="" class="icon">
              <svg data-v-48a0ebc1="" width="16" height="16" viewBox="0 0 16 16" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path data-v-48a0ebc1=""
                      d="M2.678 11.894C2.7818 11.9982 2.86133 12.124 2.91094 12.2625C2.96055 12.4009 2.97901 12.5486 2.965 12.695C2.89472 13.3726 2.76148 14.0421 2.567 14.695C3.962 14.372 4.814 13.998 5.201 13.802C5.4205 13.6908 5.67329 13.6645 5.911 13.728C6.59243 13.9097 7.29477 14.0011 8 14C11.996 14 15 11.193 15 8C15 4.808 11.996 2 8 2C4.004 2 1 4.808 1 8C1 9.468 1.617 10.83 2.678 11.894ZM2.185 15.799C1.94807 15.846 1.71037 15.889 1.472 15.928C1.272 15.96 1.12 15.752 1.199 15.566C1.28779 15.3566 1.36918 15.1441 1.443 14.929L1.446 14.919C1.694 14.199 1.896 13.371 1.97 12.6C0.743 11.37 0 9.76 0 8C0 4.134 3.582 1 8 1C12.418 1 16 4.134 16 8C16 11.866 12.418 15 8 15C7.20765 15.0011 6.41859 14.8982 5.653 14.694C5.133 14.957 4.014 15.436 2.185 15.799Z"
                      fill="white"></path>
              </svg>
            </div>
            <span data-v-48a0ebc1="">Открыть чат</span>
            <div data-v-48a0ebc1="" class="arrow">
              <svg data-v-48a0ebc1="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path data-v-48a0ebc1="" fill-rule="evenodd" clip-rule="evenodd"
                      d="M6.00061 12C6.00061 12.1989 6.07963 12.3896 6.22028 12.5303C6.36094 12.671 6.5517 12.75 6.75061 12.75L15.4401 12.75L12.2196 15.969C12.1499 16.0387 12.0946 16.1215 12.0568 16.2126C12.0191 16.3037 11.9997 16.4014 11.9997 16.5C11.9997 16.5986 12.0191 16.6962 12.0568 16.7873C12.0946 16.8785 12.1499 16.9612 12.2196 17.031C12.2893 17.1007 12.3721 17.156 12.4632 17.1938C12.5543 17.2315 12.652 17.2509 12.7506 17.2509C12.8492 17.2509 12.9469 17.2315 13.038 17.1938C13.1291 17.156 13.2119 17.1007 13.2816 17.031L17.7816 12.531C17.8515 12.4613 17.9069 12.3785 17.9447 12.2874C17.9825 12.1963 18.002 12.0986 18.002 12C18.002 11.9013 17.9825 11.8036 17.9447 11.7125C17.9069 11.6214 17.8515 11.5386 17.7816 11.469L13.2816 6.96897C13.1408 6.82814 12.9498 6.74902 12.7506 6.74902C12.5514 6.74902 12.3604 6.82814 12.2196 6.96897C12.0788 7.1098 11.9997 7.30081 11.9997 7.49997C11.9997 7.69913 12.0788 7.89014 12.2196 8.03097L15.4401 11.25L6.75061 11.25C6.5517 11.25 6.36094 11.329 6.22028 11.4696C6.07963 11.6103 6.00061 11.8011 6.00061 12Z"
                      fill="#FFB800"></path>
              </svg>
            </div>
          </a>
          </div>
        </div>
      </div>
    </transition>
  </header>
</template>

<script>
export default {
  data() {
    return {
      mobileTab: false
    }
  },
  methods: {
    logOut() {
      document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;domain=.gmdrop.webcenter.website"
      this.$root.user = null
      window.location.href = '/'
    },
    goToUrl(url) {
      this.mobileTab = false

      this.$router.push(url)
    }
  }
}
</script>